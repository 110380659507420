import React, { Fragment } from "react";
import Layout from "../components/Layout";
import Nick from "../components/speaking/Nick";
import Interview from "../components/speaking/Interview";
import { SEO } from "../components/SEO";
import { graphql } from "gatsby";
import Footer from "../components/Footer";

const Speaking = ({ data: { sanitySpeaking, sanitySite } }) => {
  return (
    <Fragment>
      <Layout headerData={sanitySite?.header}>
        <section className={"bg-black py-10"}>
          <div className={"container mx-auto py-10 text-center"}>
            <h1 className={"text-yellow-200 text-5xl"}>
              {sanitySpeaking?.heroSection?.title}
            </h1>
            <p
              className={"font-ssp font-bold max-w-4xl mx-auto text-white mt-5"}
            >
              {sanitySpeaking?.heroSection?.caption}
            </p>
            <div className="relative overflow-hidden w-full md:w-2/4 mx-auto pt-[75%] md:pt-[33.33%]">
              <iframe
                className={
                  "mx-auto mt-8 rounded-lg absolute top-0 left-0 right-0 bottom-0 w-full h-full"
                }
                src={sanitySpeaking?.heroSection?.video?.videoUrl}
                title="Video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>
        <section className="-mt-16">
          <Nick
            aboutNickSection={sanitySpeaking?.aboutSection}
            sanitySpeaking={sanitySpeaking}
          />
        </section>
        <Interview for1={sanitySpeaking?.for1} for2={sanitySpeaking?.for2} />
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Speaking;
export const Head = ({ data: { sanitySpeaking } }) => (
  <SEO metadata={sanitySpeaking?.pageMetadata || {}} />
);

export const query = graphql`
  query {
    sanitySpeaking {
      title
      pageMetadata {
        title
        schema
        keywords
        canonical
        image {
          asset {
            url
          }
        }
        description
      }
      _rawMessage
      heroSection {
        video {
          platform
          videoUrl
        }
        title
        caption
      }
      aboutSection {
        title
        _rawContent
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      for1 {
        action {
          text
          url
        }
        description
        title
      }
      for2 {
        action {
          text
          url
        }
        description
        title
      }
    }
    sanitySite {
      header {
        links {
          label
          path
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
      }
    }
  }
`;
